@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

.myEye {
    display: inline-block;
    margin-top: -15%;
    margin-left: 90%;
    position: relative;
    width: 20px;
    height: 30px;
}


@media only screen and (min-width: 768px) and (max-width: 1025px) {
    .myEye {
        display: inline-block;
        margin-top: -20%;
        margin-left: 87%;
        position: relative;
        width: 20px;
        height: 30px;
    }
}

@media (max-width: 515px) {
    .myEye {
        display: inline-block;
        margin-top: -20%;
        margin-left: 87%;
        position: relative;
        width: 20px;
        height: 30px;
    }
}

.myEyeR {
    display: inline-block;
    margin-top: -10%;
    margin-left: 90%;
    position: relative;
    width: 20px;
    height: 30px;
}


@media only screen and (min-width: 768px) and (max-width: 1025px) {
    .myEyeR {
        display: inline-block;
        margin-top: -10%;
        margin-left: 87%;
        position: relative;
        width: 20px;
        height: 30px;
    }
}

@media only screen and (min-width: 400px) and (max-width: 515px) {
    .myEyeR {
        display: inline-block;
        margin-top: -15%;
        margin-left: 87%;
        position: relative;
        width: 20px;
        height: 30px;
    }}

    @media (max-width: 395px) {
        .myEyeR {
            display: inline-block;
            margin-top: -18%;
            margin-left: 87%;
            position: relative;
            width: 20px;
            height: 30px;
        }
}

.myEyeRR {
    display: inline-block;
    margin-top: -12%;
    margin-left: 90%;
    position: relative;
    width: 20px;
    height: 30px;
}


@media only screen and (min-width: 768px) and (max-width: 1025px) {
    .myEyeRR {
        display: inline-block;
        margin-top: -10%;
        margin-left: 90%;
        position: relative;
        width: 20px;
        height: 30px;
    }
}

@media only screen and (min-width: 400px) and (max-width: 515px) {
    .myEyeRR {
        display: inline-block;
        margin-top: -20%;
        margin-left: 90%;
        position: relative;
        width: 20px;
        height: 30px;
    }}

@media (max-width: 395px) {
    .myEyeRR {
        display: inline-block;
        margin-top: -20%;
        margin-left: 90%;
        position: relative;
        width: 20px;
        height: 30px;
    }
}